<template>
    <div class="qna_create_input_wrap">
        <el-input type="text" id="title" :placeholder="$t('input_title')" v-model="title"
                  @input="parentChange('title',title)"></el-input>
<!--        <el-input type="textarea" id="content" v-model="content"
                  @input="parentChange('content', content)"></el-input>-->
        <quill-editor
            ref="myQuillEditor"
            v-model="content"
            :options="editorOption"
        />
        <div class="qna_create_file_text pt10">{{ $t('attachments') }}</div>
        <div class="item_file mb40" @click="changeType('files')">
            <el-upload
                class=""
                action=""
                :show-file-list="false"
                :file-list="files"
                :auto-upload="false"
                :on-change="handleEveryFileChange"
                v-show="files.length < 3"
            >
<!--                <div class="file_name" v-if="hasFile('files')">
                    {{ files[0].org_name ? files[0].org_name : files[0].name }}
                </div>
                <button v-if="hasFile('files')" class="upload_del_btn"
                        @click="removeList('files',0,$event)"></button>-->
                <button class="qna_create_file_btn">
                    <div class="upload_img"></div>
                    <span>{{ $t('file_upload') }}</span>
                </button>
            </el-upload>
            <div class="filebox" v-for="(file, index) in files" :key="`file${index}`">
                <div class="file_name">
                    {{ files[0].org_name ? files[0].org_name : files[0].name }}
                    <button @click="removeList('files',0,$event)"></button>
                </div>
            </div>
        </div>
        <div class="validation" v-if="validation.hasError('title')">
            {{ validation.firstError('title') }}
        </div>
        <div class="validation" v-else-if="validation.hasError('content')">
            {{ validation.firstError('content') }}
        </div>
        <div class="validation" v-else-if="validation.hasError('files') && files.length === 0">
            {{ validation.firstError('files') }}
        </div>
    </div>
</template>

<script>
import quillEditorMixins from "@/mixins/quillEditorMixins";
import util from "@/mixins/util";
import boardValidator from "@/mixins/validators/boardValidator";
import elFileMixins from "@/mixins/elFileMixins";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";

export default {
    name: "BoardWriteQnaLayout",
    mixins: [quillEditorMixins, boardValidator, elFileMixins,alertMixins],
    components: {
    },
    inject: {
        boardWriteSetData: {
            default: () => {
            }
        },
    },
    provide() {
        return{

        }
    },
    props: {
        boardData: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return{
            title: '',
            content: '',
            files: [],

            isUpload: true
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        if (this.hasData()) {
            this.initSetData();
        }
        EventBus.$on('BoardWriteQnaLayoutValidator', this.checkValidator);
    },
    beforeDestroy() {
        EventBus.$off('BoardWriteQnaLayoutValidator')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        initSetData() {
            this.title = this.boardData.wr_subject;
            this.parentChange('title', this.title);
            this.content = this.boardData.wr_content;
            this.parentChange('content', this.boardData.wr_content);
            this.content = util.htmlDecode(this.content);
            this.parentChange('content', this.content);
            $('#content').summernote('code', this.content);
            if(!util.isEmpty(this.boardData.File) && this.boardData.File.filter(file => file.a_idx != null).length > 0) {
                this.files = this.boardData.File.filter(file => file.a_idx != null)
                this.parentChange('files', this.files);
            }
        },
        checkValidator(dataArr) {
            this.$validate(dataArr)
        },
        hasData() {
            return !util.isEmpty(this.boardData) && !util.isEmpty(this.boardData.wr_id);
        },
        hasFile(type) {
            return !util.isEmpty(this[type]);
        },
        removeList(type, index, e) {
            e.stopPropagation()
            let file = this[type].splice(index, 1)[0];
            if(!util.isEmpty(file.a_idx)) {
                this.deleteFiles.push(file)
                this.parentChange(`deleteFiles`, this.deleteFiles)
            }
            this.parentChange(`${type}`, this[type])
            this.$forceUpdate();
        },
        parentChange(key, value) {
            this.boardWriteSetData(key, value)
        },
    },
    watch: {
        'content': {
            deep: true,
            handler: function (val, oldVal) {
                this.parentChange('content', this.content)
            },
        },
        'boardData': {
            deep: true,
            handler: function (val, oldVal) {
                this.initSetData()
            },
        }
    },
}
</script>

<style scoped>

</style>
